import axios from 'axios';

const axiosCreate = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_LAWYER_SQUARE_API_PROTOCOL}://${process.env.NEXT_PUBLIC_LAWYER_SQUARE_API_URL}${
    process.env.NEXT_PUBLIC_LAWYER_SQUARE_API_PORT ? `:${process.env.NEXT_PUBLIC_LAWYER_SQUARE_API_PORT}` : ''
  }/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const isAvailableUrl = () => {
  if (
    !process.env ||
    !process.env.NEXT_PUBLIC_LAWYER_SQUARE_API_PROTOCOL ||
    !process.env.NEXT_PUBLIC_LAWYER_SQUARE_API_URL ||
    !process.env.NEXT_PUBLIC_LAWYER_SQUARE_API_PORT
  ) {
    return false;
  }
  return true;
};

export default { axiosCreate, isAvailableUrl };
