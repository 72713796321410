import styled from '@emotion/styled';

export const NavStyle = styled('nav')`
  display: flex;
  justify-content: space-between;
  padding: 17px 20px 8px;
  border-bottom: 1px solid #cfcfcf;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: calc(100% - 40px);
  z-index: 3;
  .navigation {
    &--left {
      display: flex;
      .logo {
        cursor: pointer;
        margin-top: -3px;
        position: relative;
        &--link {
          display: flex;
          margin-top: 3px;
        }
        &--image {
          width: 92px;
          height: 16px;
          margin-left: 20px;
          margin-right: 4px;
        }
        &--text {
          font-size: 13px;
          color: #555555;
          font-weight: normal;
          font-family: 'Pretendard-Bold';
          display: inline-block;
          @media (max-width: 320px) {
            font-size: 11px;
          }
        }
      }
    }
    &--hamburger {
      width: 20px;
      height: 16px;
      border-top: 2px solid #999999;
      border-bottom: 2px solid #999999;
      &--centerbar {
        height: 2px;
        background-color: #999999;
        width: 100%;
        margin-top: 0px;
      }
      &--opened {
        width: 20px;
        height: 20px;
        position: relative;
        &-bar {
          position: absolute;
          top: 0px;
          left: 3px;
          width: 1px;
          height: 16px;
          background-color: #999999;
        }
        &-arrow {
          position: absolute;
          left: 9px;
          top: 3px;
          width: 10px;
          height: 10px;
          border-right: 1px solid #999999;
          border-top: 1px solid #999999;
          transform: rotate(-134deg);
          &-bar {
            position: absolute;
            top: 8px;
            left: 8px;
            width: 15px;
            height: 1px;
            background-color: #999999;
          }
        }
      }
    }
  }

  .login-container {
    margin-top: -8px;

    .nav__signup {
      width: 81px;
      font-size: 12px;
      font-family: 'Pretendard-Bold';
      background-color: #ff5500;
      color: #ffffff;
      display: flex;
      justify-content: center;
      justify-items: center;
      &-icon {
        margin-left: 2px;
        font-size: 10px;
        margin-top: 3px;
      }
    }
  }
`;
