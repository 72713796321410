import Head from 'next/head';
import { getStaticImageUrl } from '@utils/createImageUrl';
import { FunctionComponent } from 'react';

type HeadMetaTagType = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
};
const HeadMetaTag: FunctionComponent<HeadMetaTagType> = ({
  title = '로톡 변호사스퀘어',
  description = '대한민국 변호사라면, 로톡을 선택하세요.',
  image = `${getStaticImageUrl({ fileName: 'lawtalklawyersquare_metaimage_default.png' })}`,
  url = typeof window === 'object' ? location.href : '',
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="naver-site-verification" content="91aada7a7817becc751914ca34a9c0a4719cad8f" />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ko_KR" />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="315" />
      <meta name="twitter:card" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Head>
  );
};

export default HeadMetaTag;
