export const SITE_NAME = '변호사스퀘어';
export const LAWTALK_LABEL = '로톡';
export const SOLUTION_LABEL = '솔루션';
export const LAWTALK_ADS_LABEL = '로톡AD';
export const LAWTALK_INSIGHT = '로톡 인사이트';
export const LAWTALK_SIGNUP_URL = ({ pagePath, utms = {} }) => {
  const params = new URLSearchParams({
    utm_campaign: pagePath,
    utm_medium: 'sign-up_link',
    utm_source: 'lawyer-square',
    ...utms,
  });
  return `${process.env.NEXT_PUBLIC_LAWTALK_HOST}/auth/lawyer-sign-up?${params.toString()}`;
};

export const LAWTALK_ADS = [
  {
    title: '분야 광고',
    description: '통합검색 결과 페이지에 변호사 프로필 카드가 가장 먼저 노출됩니다.',
    logDescription: '의뢰인이 분야를 검색하면 검색결과 페이지 상단에 변호사 프로필 카드가 노출됩니다.',
  },
  {
    title: '지역 광고',
    description: '의뢰인이 지역을 선택하면 활동 지역 광고가 노출 됩니다.',
    logDescription: '지역으로찾기 페이지에서 변호사님의 활동 지역을 기반으로 프로필 카드가 노출됩니다.',
  },
];

export const FREE_CATEGORY_END_DATE = '2022-12-31';

// 인테리어 서비스 랜딩 페이지
export const interiorDescribeData = [
  {
    image: 'pro@2x.png',
    type: '전문성',
    title: '변호사실, 의뢰인 미팅룸, <br class="mobile" /> 사무직원 공간까지',
    describeData: [
      '변호사님과 의뢰인의 사용성을 바탕으로 각 공간의 목적·활용·동선 등을 고민하여 실용성과 미를 동시에 줄 수 있는 방법으로 디자인하여 공간을 만듭니다. (방문 맞춤견적 무료 제공)',
      '다수의 법률사무소 및 법무법인의 인테리어 포트폴리오를 보유하고 있습니다.',
    ],
  },
  {
    image: 'reasonable@2x.png',
    type: '합리적',
    title: '거품을 뺀 합리적 견적 제공',
    describeData: [
      '다년간의 시공 노하우, 디자인 및 3D 투시도 전문 인력 등 분야별 전문 인력을 내부 보유하고 있어 합리적인 견적 제공이 가능합니다.',
    ],
  },
  {
    image: 'comm@2x.png',
    type: '빠른 커뮤니케이션',
    title: '작은 변경 요청도 OK!',
    describeData: [
      '변호사님의 작은 변경 요청에도 빠른 피드백이 가능합니다.',
      '별도의 하자보수팀을 운영해 빠르고 정확한 A/S를 제공합니다.',
    ],
  },
];

export const interiorBenefitData = [
  {
    type: '개업 축하 선물',
    title: '사무소 인테리어에 필요한 <br />액자, 소품, 사인물 디자인을 <br />개업 선물로 드립니다.',
    image: 'promotion_1@2x.png',
  },
  {
    type: '프로필 촬영',
    title: '개업 후 홍보에 필요한 변호사 <br />프로필 사진을 촬영해드립니다.',
    desc: '약 25만 원 상당, 변호사 프로필 전문 <br class="mobile"/> 촬영작가가 <br class="desktop"/>촬영해드립니다.',
    image: 'promotion_2@2x.png',
  },
  {
    type: '마케팅 지원',
    title: '로톡의 광고 상품을 <br /> 한시적으로 무료 지원합니다.',
    desc: '담당 비즈니스 매니저를 통해 개업과 함께 더 많은 <br class="mobile"/> 의뢰인을 만날 수 있도록 마케팅 방향을 제안해드립니다.',
    image: 'promotion_4@2x.png',
  },
  {
    type: '사무용 가구 할인',
    title: '퍼시스, 데스커, 시디즈. <br />고급 사무용 브랜드 가구를 <br/>특별가로 제공해드립니다.',
    image: 'promotion_3@2x.png',
  },
];
