const Kakaoplus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
      <g fill="none" fillRule="evenodd">
        <g fill="#CFCFCF">
          <g>
            <g>
              <g>
                <path
                  d="M14.466 5C16.962 5 18.992 6.877 19 9.586c0 2.661-2.03 4.585-4.526 4.585h-1.736v-2.607h1.348c1.077 0 1.953-.887 1.953-1.978 0-1.092-.876-1.98-1.953-1.98h-1.984v9.714c0 .863-.698 1.68-1.55 1.68-.853 0-1.55-.817-1.55-1.68V6.89c-.005-.097-.022-.839.434-1.293 0 0 .511-.597 1.558-.597zM8 12v3H5.352C4.66 15 4 14.322 4 13.5S4.66 12 5.352 12H8z"
                  transform="translate(-1260 -5022) translate(1171 5018) translate(84) translate(5.02 4.992)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Kakaoplus;
