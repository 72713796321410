const Blog = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
      <g fill="none" fillRule="evenodd">
        <g fill="#CFCFCF">
          <g>
            <g>
              <g>
                <path
                  d="M20.659 9.47c1.038 0 1.579.716 1.579.716V9.66h1.828v5.308c0 2.805-2.452 2.934-2.725 2.94h-.755v-1.667h.453c1.331 0 1.199-1.375 1.199-1.375v-.439c-.585.76-1.653.717-1.653.717-2.459 0-2.582-2.463-2.588-2.761v-.03c0-2.663 2.103-2.87 2.57-2.883zM1.904 7.203v2.983c.76-.74 1.599-.74 1.599-.74 2.554 0 2.652 2.867 2.652 2.868 0 2.828-2.652 2.925-2.652 2.925-1.13 0-1.58-.81-1.58-.81v.614H.022v-7.84h1.882zM13.866 9.47c3.284 0 3.18 2.904 3.18 2.904 0 2.7-2.836 2.854-3.152 2.863h-.028c-2.955 0-3.104-2.43-3.109-2.813v-.05c0-2.904 3.11-2.904 3.11-2.904zM6.76 7.028c2.514 0 2.664 2.461 2.671 2.884v5.13H7.618v-4.64c0-1.6-.858-1.56-.858-1.56zm-3.671 4.137c-.668 0-1.21.541-1.21 1.209 0 .667.542 1.21 1.21 1.21.668 0 1.209-.543 1.209-1.21 0-.667-.541-1.21-1.209-1.21zm10.778 0c-.668 0-1.21.541-1.21 1.209 0 .667.542 1.21 1.21 1.21.667 0 1.209-.543 1.209-1.21 0-.667-.542-1.21-1.21-1.21zm7.247 0c-.668 0-1.21.541-1.21 1.209 0 .667.542 1.21 1.21 1.21.666 0 1.208-.543 1.208-1.21 0-.667-.54-1.21-1.208-1.21z"
                  transform="translate(-1218 -5022) translate(1171 5018) translate(42) translate(5.02 4.992)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Blog;
