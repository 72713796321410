const Plus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11">
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF">
          <g>
            <g>
              <path d="M4 0H5.2V9.429H4z" transform="translate(-333 -18) translate(274 8) rotate(90 29.05 39.95)" />
              <path d="M0 4H9.429V5.2H0z" transform="translate(-333 -18) translate(274 8) rotate(90 29.05 39.95)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Plus;
