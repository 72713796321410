/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import media from '../../shared/mediaQuery';

type ButtonProps = {
  /** 버튼 안의 내용 */
  children: React.ReactNode;
  /** 클릭했을 때 호출할 함수 */
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  theme: 'orange';
  size: 'small' | 'medium' | 'big';
  disabled?: boolean;
  name?: string;
};

/** `Button` 컴포넌트는 조절해서 사용가능합니다. */
const Button = ({ children, theme, size, disabled, onClick, name = '' }: ButtonProps) => {
  return (
    <button css={[style, themes[theme], sizes[size]]} disabled={disabled} name={name} onClick={onClick}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  theme: 'orange',
  size: 'medium',
  type: 'button',
};

const style = css`
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'Pretendard-Bold';
  width: 100%;
  &:focus {
    outline: none;
    border: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const themes = {
  orange: css`
    background: none;
    color: white;
    background-color: #ff5500;
  `,
};

const sizes = {
  small: css`
    font-size: 12px;
    height: 32px;
  `,
  medium: css`
    font-size: 14px;
    height: 40px;
  `,
  big: css`
    font-size: 22px;
    height: 68px;
  `,
};

export default Button;
