const Youtube = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
      <g fill="none" fillRule="evenodd">
        <g fill="#CFCFCF">
          <g>
            <g>
              <path
                d="M10.074 14.468v-5.68l4.92 2.84-4.92 2.84zM21.04 7.07c-.217-.815-.859-1.456-1.674-1.674C17.89 5 11.968 5 11.968 5s-5.921 0-7.398.396c-.815.218-1.457.86-1.674 1.674C2.5 8.547 2.5 11.628 2.5 11.628s0 3.08.396 4.557c.217.815.86 1.457 1.674 1.675 1.477.395 7.398.395 7.398.395s5.922 0 7.398-.395c.815-.218 1.457-.86 1.674-1.675.396-1.476.396-4.557.396-4.557s0-3.081-.396-4.558z"
                transform="translate(-1176 -5022) translate(1171 5018) translate(5.02 4.992)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Youtube;
