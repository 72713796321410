import React, { FunctionComponent } from 'react';

import Blog from '../../public/icon/Blog';
import Kakaoplus from '../../public/icon/Kakaoplus';
import Youtube from '../../public/icon/Youtube';
import FooterStyle from './footer.style';

const Footer: FunctionComponent = () => {
  return (
    <FooterStyle>
      <div className="footer--useRule">
        <div className="footer--detailLink">
          <div className="footer--title">(C) Law&Company Co., Ltd. ALL RIGHTS RESERVED.</div>
        </div>
      </div>
      <div className="footer--links">
        <a className="footer--link" href="https://www.lawtalk.is/lawcompany" target="_blank" rel="noreferrer">
          회사소개
        </a>
        <a className="footer--link" href="https://www.lawtalk.co.kr/faq/lawyer" target="_blank" rel="noreferrer">
          자주묻는질문
        </a>
      </div>
      <p className="footer--description">
        (주)로앤컴퍼니 서울시 강남구 테헤란로 420 KT선릉타워West 19층 사업자번호 : 264-81-01728 통신판매번호 :
        2022-서울강남-03264 대표자명 : 김본환 대표번호 : 02-6959-5080
      </p>
      <div className="footer--outerlinks">
        <a
          className="footer--outerlink"
          href="https://www.youtube.com/channel/UC62elmalm7jtbNZi2G7lBbw"
          target="_blank"
          rel="noreferrer"
        >
          <Youtube />
        </a>
        <a className="footer--outerlink" href="http://blog.naver.com/law-talk" target="_blank" rel="noreferrer">
          <Blog />
        </a>
        <a className="footer--outerlink" href="https://pf.kakao.com/_xhxeqxmxd" target="_blank" rel="noreferrer">
          <Kakaoplus />
        </a>
      </div>
      <div className="footer--title desktop">(C) Law&Company Co., Ltd. ALL RIGHTS RESERVED.</div>
    </FooterStyle>
  );
};

export default Footer;
