import styled from '@emotion/styled';

import media from '../../shared/mediaQuery';

const FooterStyle = styled('footer')`
  border-top: 1px solid #e0e0e0;
  position: relative;
  ${media.desktop} {
    padding: 44px 0 60px;
  }
  ${media.mobile} {
    padding: 17px 20px;
  }
  .footer--title {
    font-family: 'Gilroy-ExtraBold';
    font-size: 11px;
    color: #757575;
    ${media.desktop} {
      color: #333333;
      font-size: 12px;
      margin-top: 28px;
    }
  }
  .footer--useRule {
    ${media.desktop} {
      margin-top: 25px;
      padding-left: 28px;
      position: absolute;
      bottom: 51px;
      left: 50%;
      transform: translateX(-50%);
    }

    .footer--detailLink {
      font-size: 10px;
      color: #555555;
      width: 100%;
      display: flex;
      justify-content: space-between;
      ${media.desktop} {
        display: none;
      }
      .more-icon-wrap {
        transform: rotate(-180deg);
        margin-top: -4px;
      }
      &.fold {
        .footer--title {
          color: #333333;
        }
        .more-icon-wrap {
          transform: rotate(0deg);
          margin-top: 0px;
        }
      }
    }
  }
  .footer--links {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 10px;
    ${media.mobile} {
      margin-top: 20px;
    }
    .footer--link {
      font-size: 12px;
      color: #757575;
      font-family: 'Pretendard-Bold';
      &.bold-link {
        color: #222;
      }
    }
  }
  .footer--description {
    text-align: left;
    font-size: 12px;
    color: #555;
    font-family: 'Pretendard-Light';
    line-height: 20px;
    margin-bottom: 7px;
    ${media.desktop} {
      width: 590px;
    }
    ${media.mobile} {
      margin-top: 3px;
      width: 100%;
    }
  }
  .footer--outerlinks {
    display: flex;
    margin-top: 20px;
    ${media.desktop} {
      position: absolute;
      right: -1px;
      bottom: 55px;
    }
    .footer--outerlink {
      width: 33px;
      height: 33px;
      border: 1px solid #e0e0e0;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 6px;
    }
  }
`;

export default FooterStyle;
