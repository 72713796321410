import { FunctionComponent } from 'react';
import AlertWrapper from './alert.style';
type AlertType = {
  title: string;
  description: string;
  clickConfirm: () => void;
};
const Alert: FunctionComponent<AlertType> = ({ title, description, clickConfirm }) => {
  return (
    <AlertWrapper>
      <div className="alert--wrap">
        <p className="alert--title">{title}</p>
        <p className="alert--description" dangerouslySetInnerHTML={{ __html: description }} />
        <button className="alert--button" onClick={clickConfirm}>
          확인
        </button>
      </div>
    </AlertWrapper>
  );
};

export default Alert;
