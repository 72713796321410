const Arrow = ({
  color = '#757575',
  width = '12px',
  height = '7px',
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 7" version="1.1" xmlns="http://www.w3.org/2000/svg" fill={color}>
      <title>icon/01sign/drop_big</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="홈/로톡소개-모바일" transform="translate(-337.000000, -2146.000000)" fill={color}>
          <g id="Group-20" transform="translate(20.000000, 2061.000000)">
            <g id="Group-14" transform="translate(0.000000, 63.000000)">
              <g id="Combined-Shape" transform="translate(311.000000, 13.000000)">
                <path
                  d="M8.94974747,5.79289322 L8.94974747,12.7928932 L15.9497475,12.7928932 L15.9497475,13.7928932 L7.94974747,13.7928932 L7.94974747,5.79289322 L8.94974747,5.79289322 Z"
                  transform="translate(11.949747, 9.792893) rotate(-45.000000) translate(-11.949747, -9.792893) "
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Arrow;
