import styled from '@emotion/styled';
import { getStaticImageUrl } from '@utils/createImageUrl';

const MobileGnbStyle = styled('div')`
  background-color: #f6f6f6;
  height: calc(100vh - 50px);
  .mobile-gnb {
    &__signup {
      width: 69px;
      height: 69px;
      border-radius: 100%;
      border: 3px solid #ff5500;
      display: block;
      margin: 0 auto;
      text-align: center;
      position: relative;
      z-index: 1;
      &-backimg {
        background-image: url(${getStaticImageUrl({ fileName: 'lawyerdefault.png' })});
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0px;
        top: 0px;
        opacity: 0.2;
        width: 63px;
        height: 63px;
        border-radius: 100%;
        z-index: -1;
      }
      &-wrap {
        background-color: #ffffff;
        padding: 27px 0;
      }
      &-text {
        font-family: 'Pretendard-Bold';
        font-size: 15px;
        color: #ff5500;
        display: block;
      }
      &-plus {
        position: absolute;
        bottom: -4px;
        right: -3px;
        width: 18px;
        height: 18px;
        border: 1px solid #ffffff;
        background-color: #ff5500;
        border-radius: 100%;
        color: #ffffff;
        font-size: 10px;
        line-height: 18px;
      }
    }
    &__menus {
      padding-top: 32px;
      padding-left: 20px;
      padding-bottom: 24px;
      background-color: #f6f6f6;
      a {
        display: block;
        font-family: 'Pretendard-Bold';
        font-size: 16px;
        color: #cfcfcf;
        &:not(:first-of-type) {
          margin-top: 24px;
        }
        &.active {
          color: #333333;
        }
      }
    }
    &__specials-menu {
      width: calc(100% - 40px);
      display: flex;
      flex-direction: column;
      gap: 11px;
      margin-left: 20px;
      padding: 20.5px 0;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      &__item {
        font-size: 12px;
        font-family: 'Pretendard-Bold';
        color: #999;
        &:hover {
          color: #ff5500;
        }
      }
    }
    &__lawtalk {
      font-size: 12px;
      font-family: 'Pretendard-Bold';
      color: #999999;
      margin-left: 20px;
      margin-top: 15px;
      display: block;
    }
  }
`;

export default MobileGnbStyle;
