import { FunctionComponent } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import MobileGnbWrapper from './mobilegnb.style';
import { LAWTALK_LABEL, SOLUTION_LABEL, LAWTALK_ADS_LABEL, LAWTALK_INSIGHT, LAWTALK_SIGNUP_URL } from '@shared/values';
import { getUtmsFromQuery } from '../../utils/utmUtil';
import { sendAmplitudeEvent } from '../../utils/amplitude';

const MobileGnb: FunctionComponent<{ openMobileMenu: (open: boolean) => void }> = ({ openMobileMenu }) => {
  const router = useRouter();
  const goSignup = () => {
    const pathname = router.pathname || '';
    const utms = getUtmsFromQuery(router.query);
    const signupUrl = LAWTALK_SIGNUP_URL({ pagePath: pathname, utms: utms });
    // 회원가입 버튼 클릭 + 왼쪽 메뉴 영역 클릭 이벤트 분리 송출
    sendAmplitudeEvent('sqr_click_sign-up', { label: 'left' });
    sendAmplitudeEvent('sqr_click_main_menu', { label: 'sign-up' });
    window.open(signupUrl);
  };
  return (
    <MobileGnbWrapper>
      <div className="mobile-gnb__signup-wrap">
        <button className="mobile-gnb__signup" onClick={goSignup}>
          <div className="mobile-gnb__signup-backimg" />
          <span className="mobile-gnb__signup-text">회원가입</span>
          <span className="mobile-gnb__signup-plus">┼</span>
        </button>
      </div>
      <div className="mobile-gnb__menus">
        <div
          onClick={() => {
            openMobileMenu(false);
          }}
        >
          <Link href="/">
            <a
              className={router.pathname === '/' ? 'active' : ''}
              onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'main' })}
            >
              {LAWTALK_LABEL} 소개
            </a>
          </Link>
          <Link href="/product/solution" shallow={true}>
            <a
              className={router.pathname === '/product/solution' ? 'active' : ''}
              onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'solution' })}
            >
              {SOLUTION_LABEL} 소개
            </a>
          </Link>
          <Link href="/product/ads">
            <a
              className={router.pathname === '/product/ads' ? 'active' : ''}
              onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'ads' })}
            >
              {LAWTALK_ADS_LABEL} 소개
            </a>
          </Link>
          <Link href="/insight">
            <a
              className={router.pathname === '/insight' || router.pathname === '/insights/[insightId]' ? 'active' : ''}
              onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'insight' })}
            >
              {LAWTALK_INSIGHT}
            </a>
          </Link>
        </div>
      </div>
      <div className="mobile-gnb__specials-menu">
        <a
          className="mobile-gnb__specials-menu__item"
          href="/preview"
          target="_blank"
          onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'preview' })}
        >
          변호사 개업 미리보기
        </a>
        {/* 진입점 비활성화(22/11/7-) */}
        {/* <a
          className="mobile-gnb__specials-menu__item"
          href="/interior"
          target="_blank"
          onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'interior' })}
        >
          개업 인테리어 서비스
        </a> */}
        <a
          className="mobile-gnb__specials-menu__item"
          href="/fursys"
          target="_blank"
          onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'fursys' })}
        >
          사무실 가구 특가 안내
        </a>
      </div>
      <a
        className="mobile-gnb__lawtalk"
        href="https://www.lawtalk.co.kr"
        target="_blank"
        onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'lawtalk' })}
      >
        로톡 홈페이지 바로가기
      </a>
    </MobileGnbWrapper>
  );
};

export default MobileGnb;
