import { FunctionComponent } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { NavStyle } from './navigation.styles';
import { SITE_NAME, LAWTALK_SIGNUP_URL } from '@shared/values';
import Button from '@element/Button';
import Plus from '../../public/icon/Plus';
import { getUtmsFromQuery } from '../../utils/utmUtil';
import { sendAmplitudeEvent } from '../../utils/amplitude';

type Props = {
  openMobileMenu: (isOpen: boolean) => void;
  showMobileGnb: boolean;
};

const Navigation: FunctionComponent<Props> = ({ openMobileMenu, showMobileGnb }) => {
  const router = useRouter();
  const goSignup = () => {
    const pathname = router.pathname || '';
    const utms = getUtmsFromQuery(router.query);
    const signupUrl = LAWTALK_SIGNUP_URL({ pagePath: pathname, utms: utms });
    sendAmplitudeEvent('sqr_click_sign-up', { label: 'top' });
    window.open(signupUrl);
  };
  return (
    <NavStyle className="navigation">
      <div className="navigation--left">
        {showMobileGnb ? (
          <button className="navigation--hamburger--opened" onClick={() => openMobileMenu(false)}>
            <div className="navigation--hamburger--opened-bar" />
            <div className="navigation--hamburger--opened-arrow" />
            <div className="navigation--hamburger--opened-arrow-bar" />
          </button>
        ) : (
          <button className="navigation--hamburger" onClick={() => openMobileMenu(true)}>
            <div className="navigation--hamburger--centerbar" />
          </button>
        )}

        <div className="logo" onClick={() => openMobileMenu(false)}>
          <Link href="/">
            <a className="logo--link" onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'home' })}>
              <img src={'/lawtalkLogo.png'} className="logo--image" />
              <span className="logo--text">{SITE_NAME}</span>
            </a>
          </Link>
        </div>
      </div>
      <div className={`login-container`}>
        <div className="nav__signup">
          <Button size="small" onClick={goSignup}>
            회원가입{' '}
            <span className="nav__signup-icon">
              <Plus />
            </span>
          </Button>
        </div>
      </div>
    </NavStyle>
  );
};

export default Navigation;
