/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FunctionComponent, useState } from 'react';
import Footer from '@component/Footer/Footer';
import DesktopGnb from '@component/DesktopGnb';
import MobileGnb from '@component/MobileGnb';
import Navagation from '@component/Navigation';
import media from '@shared/mediaQuery';
import Head from 'next/head';

interface LayoutType {
  children: React.ReactNode;
}

export const MainLayout: FunctionComponent<LayoutType> = ({ children }: LayoutType) => {
  const [showMobileGnb, setShowMobileGnb] = useState(false);
  const openMobileMenu = (open: boolean) => {
    setShowMobileGnb(open);
    if (open) {
      document.body.classList.add('body-scroll-ban');
    } else {
      document.body.classList.remove('body-scroll-ban');
    }
  };

  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,maximum-scale=1,minimum-scale=1,initial-scale=1.0" />
        <meta name="format-detection" content="telephone=no" />
      </Head>
      <div css={[style]}>
        <div>
          <div className="gnb desktop">
            <DesktopGnb />
          </div>
          <div className="mobile-navigation-wrap mobile">
            <Navagation openMobileMenu={openMobileMenu} showMobileGnb={showMobileGnb} />
          </div>
          <div className={`gnb mobile ${showMobileGnb && 'open'}`}>
            <MobileGnb openMobileMenu={openMobileMenu} />
          </div>
        </div>

        <div className={`body-container`}>
          {children}
          <div className="footer-container">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

const style = css`
  ${media.desktop} {
    display: flex;
  }

  .gnb {
    ${media.mobile} {
      position: fixed;
      width: 100%;
      z-index: 6;
      top: 50px;
      left: -100%;
      transition: left 0.3s ease-out;
      &.open {
        left: 0px;
        transition: left 0.3s ease-out;
      }
    }
    ${media.desktop} {
      border-right: 1px solid #e0e0e0;
      width: 198px;
      flex: none;
      background-color: #ffffff;
      z-index: 1;
    }
  }
  .mobile-navigation-wrap {
    ${media.mobile} {
      height: 50px;
    }
  }
  .body-container {
    ${media.desktop} {
      width: calc(100% - 200px);
      flex: none;
    }
  }
  .footer-container {
    ${media.desktop} {
      width: 944px;
      margin: 0 auto;
    }
  }
`;
