import styled from '@emotion/styled';
import { getStaticImageUrl } from '@utils/createImageUrl';

const DesktopGnbStyle = styled('div')`
  padding-top: 22px;
  position: fixed;
  width: 198px;
  top: 0;
  left: 0;
  z-index: 1;
  .desktop-gnb {
    &--title {
      text-align: center;
      &-logo {
        width: 100px;
        height: 16px;
        margin: 0 auto;
        display: block;
      }
      &-name {
        font-size: 12px;
        color: #555555;
        font-family: 'Pretendard-Bold';
        margin-top: 2px;
      }
    }
    &--signup {
      width: 90px;
      height: 90px;
      border-radius: 100%;
      border: 3px solid #ff5500;
      display: block;
      margin: 16px auto 0;
      text-align: center;
      position: relative;
      &-backimg {
        background-image: url(${getStaticImageUrl({ fileName: 'lawyerdefault.png' })});
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        left: 0;
        top: -5px;
        opacity: 0.2;
        width: 90px;
        height: 90px;
        border-radius: 100%;
        z-index: -1;
      }
      &-text {
        font-family: 'Pretendard-Bold';
        font-size: 16px;
        color: #ff5500;
        display: block;
      }
      &-plus {
        position: absolute;
        bottom: -3px;
        right: 0px;
        width: 18px;
        height: 18px;
        border: 1px solid #ffffff;
        background-color: #ff5500;
        border-radius: 100%;
        color: #ffffff;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    &--menus {
      width: calc(100% - 40px);
      margin-left: 20px;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      margin-top: 28px;
      padding-top: 10px;
      padding-bottom: 22px;
      a {
        display: block;
        font-family: 'Pretendard-Bold';
        font-size: 16px;
        color: #cfcfcf;
        margin-top: 16px;
        &.active {
          color: #333333;
        }
      }
    }
    &__special-menu {
      display: flex;
      flex-direction: column;
      gap: 11px;
      width: calc(100% - 40px);
      border-bottom: 1px solid #e0e0e0;
      margin-left: 20px;
      padding: 21px 0;
      color: #ff5500;
      &__item {
        font-family: 'Pretendard-Bold';
        font-size: 12px;
        color: #999;
        &:hover {
          color: #ff5500;
        }
      }
    }
    &--lawtalk {
      font-size: 12px;
      font-family: 'Pretendard-Bold';
      color: #999999;
      margin-left: 20px;
      display: block;
      padding-top: 18px;
    }
  }
`;
export default DesktopGnbStyle;
