import styled from '@emotion/styled';

const AlertStyle = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.7);
  overflow-y: auto;
  .alert {
    &--wrap {
      margin: auto;
      margin-top: 228px;
      width: 335px;
      padding-top: 44px;
      background-color: #ffffff;
      text-align: center;
    }
    &--title {
      font-family: 'Pretendard-Bold';
      font-size: 20px;
      color: #333333;
      line-height: 28px;
    }
    &--description {
      margin-top: 16px;
      font-size: 14px;
      color: #555555;
      line-height: 20px;
    }
    &--button {
      margin-top: 36px;
      width: 100%;
      height: 48px;
      background-color: #f6f6f6;
      color: #555555;
      font-size: 15px;
      font-family: 'Pretendard-Bold';
    }
  }
`;

export default AlertStyle;
