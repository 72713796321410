import { FunctionComponent } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import DesktopGnbWrapper from './desktopgnb.style';
import {
  SITE_NAME,
  LAWTALK_LABEL,
  SOLUTION_LABEL,
  LAWTALK_ADS_LABEL,
  LAWTALK_INSIGHT,
  LAWTALK_SIGNUP_URL,
} from '@shared/values';

import { getUtmsFromQuery } from '../../utils/utmUtil';
import { PlusIcon } from '../../public/icon/PlusIcon';
import { sendAmplitudeEvent } from '../../utils/amplitude';

const DesktopGnb: FunctionComponent = () => {
  const router = useRouter();
  const goSignup = () => {
    const pathname = router.pathname || '';
    const utms = getUtmsFromQuery(router.query);
    const signupUrl = LAWTALK_SIGNUP_URL({ pagePath: pathname, utms: utms });
    // 회원가입 버튼 클릭 + 왼쪽 메뉴 영역 클릭 이벤트 분리 송출
    sendAmplitudeEvent('sqr_click_sign-up', { label: 'left' });
    sendAmplitudeEvent('sqr_click_main_menu', { label: 'sign-up' });
    window.open(signupUrl);
  };
  return (
    <DesktopGnbWrapper>
      <Link href="/">
        <a className="desktop-gnb--title" onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'home' })}>
          <img src={'/lawtalkLogo.png'} className="desktop-gnb--title-logo" />
          <p className="desktop-gnb--title-name">{SITE_NAME}</p>
        </a>
      </Link>
      <button className="desktop-gnb--signup" onClick={goSignup}>
        <div className="desktop-gnb--signup-backimg" />
        <span className="desktop-gnb--signup-text">회원가입</span>
        <div className="desktop-gnb--signup-plus">
          <PlusIcon />
        </div>
      </button>
      <div className="desktop-gnb--menus">
        <Link href="/">
          <a
            className={router.pathname === '/' ? 'active' : ''}
            onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'main' })}
          >
            {LAWTALK_LABEL} 소개
          </a>
        </Link>
        <Link href="/product/solution">
          <a
            className={router.pathname === '/product/solution' ? 'active' : ''}
            onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'solution' })}
          >
            {SOLUTION_LABEL} 소개
          </a>
        </Link>
        <Link href="/product/ads">
          <a
            className={router.pathname === '/product/ads' ? 'active' : ''}
            onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'ads' })}
          >
            {LAWTALK_ADS_LABEL} 소개
          </a>
        </Link>
        <Link href="/insight">
          <a
            className={router.pathname === '/insight' || router.pathname === '/insight/[insightId]' ? 'active' : ''}
            onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'insight' })}
          >
            {LAWTALK_INSIGHT}
          </a>
        </Link>
      </div>
      <div className="desktop-gnb__special-menu">
        <Link href="/preview">
          <a
            className="desktop-gnb__special-menu__item"
            target="_blank"
            onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'preview' })}
          >
            변호사 개업 미리보기
          </a>
        </Link>
        {/* 진입점 비활성화(22/11/7-) */}
        {/* <Link href="/interior">
          <a
            className="desktop-gnb__special-menu__item"
            target="_blank"
            onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'interior' })}
          >
            개업 인테리어 서비스
          </a>
        </Link> */}
        <Link href="/fursys">
          <a
            className="desktop-gnb__special-menu__item"
            target="_blank"
            onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'fursys' })}
          >
            사무실 가구 특가 안내
          </a>
        </Link>
      </div>
      <a
        className="desktop-gnb--lawtalk"
        href="https://www.lawtalk.co.kr"
        target="_blank"
        onClick={() => sendAmplitudeEvent('sqr_click_main_menu', { label: 'lawtalk' })}
      >
        로톡 홈페이지 바로가기
      </a>
    </DesktopGnbWrapper>
  );
};

export default DesktopGnb;
